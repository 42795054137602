.has-wrapper {
  position: relative;
}
.has-input {
  border: none;
}
.has-icon {
  position: absolute;
  right: -0px;
  margin-right: 5px;
  top: 4px;
  font-size: 20px;
}
.login-input {
  display: block;
  width: 100%;
}

.icon {
  position: absolute;
  right: 1px;
  top: 4px;
  font-size: 20px;
  text-align: center;
}

/* links css */

.advance-div {
  justify-content: center;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-width: 1px;
  border-radius: 2px;
  border: 1px solid #aeb6bf;
}
.setting-icon {
  padding: 0px 10px 0px 10px;
}
.ad-text {
  margin: 10px 10px 10px 0px;
}

/* //edit */

.main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.copy-icon {
  font-size: 20px;
  margin-right: 20px;
}
.dot-icon {
  font-size: 20px;
}

/* //pagination */
.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin-top: 20px;
  color: #02071a;
}

.pagination li a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00b3f4;
  color: #00b3f4;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  /* color: #fff; */
  color: #00b3f4;
  background-color: #00b3f4;
}

.pagination__link--disabled a {
  /* color: rgb(198, 197, 202); */
  color: #00b3f4;
  border: 1px solid #00b3f4;
}

.inputs {
  margin-top: 10px;
}
.table-data {
  font-weight: bold;
}
.has-icon {
  position: absolute;
  right: -0px;
  margin-right: 5px;
  top: 4px;
  font-size: 20px;
}
.has-wrapper {
  position: relative;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: left;
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 10px;
}

input[type="textarea"] {
  resize: none;
}

