

// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}



.bg-login{
    background-image: url(../../../images/users/backgroud.jpg);
    padding: 60px 0px;
    background-size: cover;
    background-position: center center;
    position: relative;
    border-radius: 0px 0px 50% 50%;
}

.bg-login-overlay{
    position: absolute;
    background: $bg-gradient;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 50% 50%;
    opacity: 0.8;
}


.account-pages {
    .logo-admin {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0px auto;
        width: 74px;
        height: 74px;
        line-height: 74px;
        background: $card-bg;
        border-radius: 50%;
        text-align: center;
        box-shadow: $box-shadow;
      }
}

// error page

.error-page{
    text-transform: uppercase;
    font-size: 60px;
    line-height: .7;
    position: relative;
}


