@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}
.rangeslider__fill {
  background-color: #556ee6 !important;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  margin: 2em 0;
}
//Crypto enteries
.bs-select select {
  width: auto !important;
}

// .apexcharts-canvas {
//   text {
//     fill: #adb5bd !important;
//   }
// }

.input-group-append,
.input-group-prepend {
  display: flex;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.steps a.nav-link {
  background-color: #c3cef8;
  color: #3b5de7;
}
.steps a.nav-link .number {
  border-color: #3b5de7;
}
.pagination .pagination {
  margin-bottom: 0px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}

.btn-primary {
  border: 1px solid #00b3f4;
  background-color: #00b3f4;
}
.btn-primary:hover {
  border: 1px solid #00b3f4;
  background-color: #00b3f4;
}
.btn-primary:focus {
  border: 1px solid #00b3f4;
  background-color: #00b3f4;
  box-shadow: none;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00b3f4;
  border-color: #00b3f4;
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #00b3f4;
  border-color: #00b3f4;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.permissions-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

@media (max-width: 1250px) {
  .permissions-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (max-width: 850px) {
  .permissions-menu {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}


.project-title{
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.back {
  filter: blur(8px);
}
.loader {
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  align-items: center;
  margin: 0 auto;
  right: 0;
  //   width: 100vw;
  height: 100%;
  // background: #404040;
  background: rgba(255, 255, 255, 0.7);
  color: #3b5de7;
  // color: aliceblue;
  //   filter: blur(8px);
}

//? flipcard

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  // width: 300px;
  height: 10rem;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

//? overview-card

@media (max-width: 1370px) {
  .overview-card {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (max-width: 1060px) {
  .overview-card {
    flex: 0 0 auto;
    width: 100%;
  }
}

.overview-card-2:hover{
  height: 11rem;
}
.overview-card-2{
  height: 10rem;
}


.p-card-container{
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-card {
  // color: white !important;
  // background-color: #2E5BFF;
  background-image: url("../images/construction-1.jpg");
  // background: linear-gradient(rgb(40,194,245,0.2),rgba(2, 32, 43, 0.2));
  // background-color: rgb(40,194,245,0.2);
  background-size: cover;
  background-position: center;
  opacity: 0.5;
}

.p-card-logo{
  background: radial-gradient(rgb(2, 133, 177),rgb(0, 156, 212));
  box-shadow: 0px 0px 4px rgb(4, 33, 43);
}